import React from "react";
import "./App.css";
import { useAuth } from "./hooks/useAuth";
import DefaultLayout from "./layouts/DefaultLayout";
import OnlyTikTok from "./pages/OnlyTikTok";
import TiktokAds from "./pages/TiktokAds";
import { userInfo } from "./utils";
// import Snowfall from "react-snowfall";

function App() {
  useAuth();

  // const snowflake1 = document.createElement("img");
  // snowflake1.src = "/snowflake.png";

  // const snowFallImages = [snowflake1];

  return (
    <div style={{ position: "relative" }}>
      {/* <Snowfall
        snowflakeCount={30}
        speed={[0.5, 3]}
        wind={[-0.5, 2]}
        images={snowFallImages}
        radius={[5, 20]}
        opacity={[0.1, 0.2]}
        rotationSpeed={[-1, 1]}
        style={{ zIndex: 1001 }}
      /> */}

      {userInfo?.user?.role.includes("tiktok1") ? (
        <OnlyTikTok />
      ) : userInfo?.user?.role.includes("tiktok_ads") ? (
        <TiktokAds />
      ) : (
        <DefaultLayout />
      )}
    </div>
  );
}

export default App;
