import { Button, Form, Input, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import readNumber from "read-vn-number";
import addCommas from "../../../utils/addCommas";
import removeNonNumeric from "../../../utils/removeNonNumeric";
import dayjs from "dayjs";

const UpdateKPIForm = ({
  visible = false,
  onUpdate,
  onCancel,
  loading = false,
  updateItem,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [kpi, setKPI] = useState();

  const handleFormValuesChange = (_, allValues) => {
    form.setFieldsValue({
      kpi: addCommas(removeNonNumeric(allValues.kpi)),
    });
    setKPI(addCommas(removeNonNumeric(allValues.kpi)));
  };

  useEffect(() => {
    form.resetFields();
  }, [form, updateItem]);

  return (
    <>
      {updateItem && (
        <Modal
          destroyOnClose
          width={600}
          open={visible}
          title={t("Cập nhật bản ghi")}
          onCancel={() => {
            form.resetFields();
            onCancel();
          }}
          footer={[
            <Button onClick={onCancel}>{t("cancel")}</Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    console.log("values", values);
                    onUpdate(values);
                    form.resetFields();
                  })
                  .catch((info) => {
                    console.log("Validate Failed:", info);
                  });
              }}
            >
              {t("ok")}
            </Button>,
          ]}
        >
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onValuesChange={handleFormValuesChange}
            initialValues={{
              month: updateItem?.month,
              year: updateItem?.year,
              kpi: updateItem?.kpi,
            }}
          >
            <Form.Item
              name="month"
              label={t("month")}
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc!",
                },
              ]}
            >
              <InputNumber
                min={1}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item
              name="year"
              label={t("year")}
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc!",
                },
              ]}
            >
              <InputNumber
                min={2021}
                max={dayjs().year() + 1}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            <Form.Item
              name="kpi"
              label={t("KPI")}
              rules={[
                {
                  required: true,
                  message: "Trường này là bắt buộc!",
                },
              ]}
            >
              <Input
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
            {kpi && (
              <p className="fee-text">
                KPI:{" "}
                <span>
                  {readNumber(parseInt(kpi.split(".").join("")))} đồng
                </span>
              </p>
            )}
          </Form>
        </Modal>
      )}
    </>
  );
};

export default UpdateKPIForm;
