import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Select,
  Spin,
  Table,
  Tooltip,
  Typography,
  message,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Confirm } from "react-st-modal";
import { config, userInfo } from "../../../utils";
import Ripple from "../../Common/Ripple";
import "../SettingCosultant.css";
import CreateKPIForm from "./CreateKPIForm";
import UpdateKPIForm from "./UpdateKPIForm";

const KPITable = ({ defaultlDataSource = [], cosultant }) => {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState(defaultlDataSource);
  const [updateItem, setUpdateItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [year, setYear] = useState(dayjs().year());

  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const years = useMemo(() => {
    if (userInfo?.user?.role.includes("sales_mn")) {
      return [dayjs().year()];
    }

    const currYear = dayjs().year();
    const yearsArr = [];
    for (let i = 2021; i <= currYear + 1; ++i) {
      yearsArr.push(i);
    }
    return yearsArr;
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/cosultants/${cosultant._id}?year=${year}`,
        config
      );

      setDataSource(data.data?.kpi);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [cosultant, year]);

  useEffect(() => {
    if (cosultant && year) {
      fetchData();
    }
  }, [fetchData, cosultant, year]);

  useEffect(() => {
    if (dataSource) {
      dataSource.forEach((data) => {
        data.key = data._id;
      });
    }
  }, [dataSource]);

  const columns = [
    {
      title: t("action"),
      dataIndex: "",
      fixed: "left",
      render: (_, record) => (
        <>
          <Tooltip
            placement="rightBottom"
            title={`${t("recordLastModified")}: ${dayjs(
              record.updatedAt
            ).format("DD/MM/YYYY HH:mm:ss")}`}
          >
            <Button
              size="small"
              icon={<EditOutlined />}
              onClick={() => {
                setVisibleUpdate(true);
                setUpdateItem(record);
              }}
            >
              <Ripple color="#ffcd0f" />
            </Button>
          </Tooltip>
        </>
      ),
      width: 120,
    },
    {
      title: t("month"),
      dataIndex: "month",
      width: 120,
      render: (_, record) => <>{record.month || "/"}</>,
    },
    {
      title: t("year"),
      dataIndex: "year",
      width: 120,
      render: (_, record) => <>{record.year || "/"}</>,
    },
    {
      title: t("KPI"),
      dataIndex: "kpi",
      width: 120,
      render: (_, record) => <>{record.kpi || "/"}</>,
    },
  ];

  const onCreate = async (values) => {
    try {
      if (!cosultant) return;
      setLoadingCreate(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/cosultants/${cosultant._id}/kpi`,
        values,
        config
      );

      message.success("Thêm thành công");
      setVisibleCreate(false);
      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCreate(false);
    }
  };

  const onUpdate = async (values) => {
    try {
      if (!updateItem?._id) return;
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/cosultants/${cosultant._id}/kpi/${updateItem?._id}`,
        values,
        config
      );
      fetchData();
      setVisibleUpdate(false);
      message.success("Sửa thành công");
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const handleDelete = async () => {
    setLoadingDelete(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/cosultants/${cosultant._id}/kpi/${id}`,
        config
      );
    };

    const result = await Confirm(t("confirmDelete"));

    if (result) {
      selectedRowKeys.forEach((id) => {
        deleteById(id);
      });

      setLoadingDelete(false);
      setSelectedRowKeys([]);
      fetchData();

      message.success(t("deletedSuccessfully"));
    } else {
      setLoadingDelete(false);
      setSelectedRowKeys([]);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      {loading ? (
        <div
          style={{
            height: 300,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {hasSelected && (
                <div className="delete-zone">
                  <Button
                    className="delete-btn"
                    type="default"
                    onClick={handleDelete}
                    disabled={!hasSelected}
                    loading={loadingDelete}
                    icon={<DeleteOutlined />}
                  >
                    {t("delete")}
                    <Ripple />
                  </Button>
                  <span style={{ marginInline: 8 }}>
                    {hasSelected ? (
                      <Typography.Text>
                        {selectedRowKeys.length} {t("selected")}
                      </Typography.Text>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              )}
              <Button
                icon={<FileAddOutlined />}
                onClick={() => {
                  setVisibleCreate(true);
                }}
                type="primary"
              >
                {t("addKPI")}
              </Button>
            </div>

            <div className="statistics-time">
              <Select
                style={{ width: 120 }}
                defaultValue={year}
                onChange={(value) => setYear(value)}
              >
                {years.map((year) => (
                  <Select.Option value={year}>{year}</Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <Table
            pagination={false}
            rowSelection={rowSelection}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: "max-content" }}
            showSorterTooltip={true}
            sticky
          />
          <CreateKPIForm
            visible={visibleCreate}
            onCreate={onCreate}
            onCancel={() => {
              setVisibleCreate(false);
            }}
            loading={loadingCreate}
          />
          <UpdateKPIForm
            visible={visibleUpdate}
            onUpdate={onUpdate}
            onCancel={() => {
              setVisibleUpdate(false);
              setUpdateItem(null);
            }}
            updateItem={updateItem}
            loading={loadingUpdate}
          />
        </div>
      )}
    </div>
  );
};

export default KPITable;
