import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CreateForm = ({ visible, onCreate, onCancel, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Modal
      className="class-setting"
      open={visible}
      title={t("consultant")}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t("cancel")}</Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate(values);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          {t("ok")}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="fullname" label={t("consultant")}>
          <Input defaultValue="" placeholder={`${t("e.g.")}Trần Mỹ Linh`} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateForm;
